<template>
    <v-app id="inspire">
        <div class="px-4">
            <loading
                :is-show="loadingDialog"
                :is-done="loadingFinished"
                :is-html="isHtml"
                :message="loadingMessage"
                v-on:close-dialog="closeDialog"
            />
            <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
            <v-container class="container--fluid grid-list-md text-center">
                <h1>Merchant Groups</h1>
            </v-container>
            <v-card elevation="2" outlined shaped tile class="pa-1">
                <div
                    class="d-flex flex-no-wrap justify-space-between"
                    v-if="hasPermission('ROLE_PAYCONNECT_VIEW_GROUP_MERCHANT')"
                >
                    <v-card-title>
                        <v-container grid-list-md text-xs-center>
                            <v-layout row wrap>
                                <v-flex xs12 sm6>
                                    <v-text-field
                                        label="Name"
                                        v-model="query.name"
                                        single-line
                                        outlined
                                        @change="search($event, 'name')"
                                        data-cy="form-name"
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-text-field
                                        label="Remarks"
                                        v-model="query.remarks"
                                        single-line
                                        outlined
                                        @change="search($event, 'remarks')"
                                        data-cy="form-remarks"
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-title>
                </div>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card-title>
                        <v-container grid-list-md text-xs-center>
                            <v-layout wrap>
                                <v-flex xs12 sm6 v-if="hasPermission('ROLE_PAYCONNECT_VIEW_GROUP_MERCHANT')">
                                    <v-btn
                                        :block="$vuetify.breakpoint.mdAndDown"
                                        class="mt-2"
                                        color="success"
                                        @click="queryData(true)"
                                        data-cy="button-search"
                                    >
                                        <v-icon dark left> mdi-magnify</v-icon> Search
                                    </v-btn>
                                </v-flex>
                                <v-flex xs12 sm6 v-if="hasPermission('ROLE_PAYCONNECT_CREATE_GROUP_MERCHANT')">
                                    <v-btn
                                        :block="$vuetify.breakpoint.mdAndDown"
                                        class="mt-2"
                                        color="success"
                                        @click.stop="showGroupMerchantForm('CREATED')"
                                        data-cy="button-create"
                                        >Create Merchant Group</v-btn
                                    >
                                    <group-merchant-form
                                        ref="GroupMerchantForm"
                                        :selected-item="selectedGroupMerchant"
                                        :merchants="merchants"
                                        :users="users"
                                        :is-show="showFormDialog"
                                        :form-type="groupMerchantFormType"
                                        v-on:close-dialog="closeDialog"
                                        v-on:save-group-merchant="saveGroupMerchant"
                                    />
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-title>
                </div>
            </v-card>

            <v-data-table
                :hide-default-header="true"
                :headers="headers"
                :items="displayGroupMerchants"
                :loading="loading"
                :page="page"
                :pageCount="numberOfPages"
                :options.sync="options"
                :server-items-length="totalGroupMerchants"
                class="mb-14"
                :footer-props="{ 'items-per-page-options': [10, 20, 50] }"
            >
                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr :colspan="headers.length">
                            <th v-for="header in headers" :key="header.text">
                                <span class="d-flex justify-center">{{ header.text }}</span>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body="{ items }">
                    <tbody>
                        <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                            <td>
                                <span class="d-flex justify-center"> {{ item.id }} </span>
                            </td>
                            <td>
                                <span class="d-flex justify-center"> {{ item.name }} </span>
                            </td>
                            <td>
                                <span class="d-flex justify-center"> {{ item.dateCreated }} </span>
                            </td>
                            <td>
                                <span class="d-flex justify-center">
                                    <v-btn fab dark x-small color="green" class="mr-2" @click.stop="viewGroupMerchant(item)">
                                        <v-icon dark medium>mdi-magnify-plus</v-icon>
                                    </v-btn>
                                    <v-btn
                                        fab
                                        dark
                                        x-small
                                        color="green"
                                        class="mr-2"
                                        @click.stop="showGroupMerchantForm('UPDATE', item)"
                                        v-if="hasPermission('ROLE_PAYCONNECT_UPDATE_GROUP_MERCHANT')"
                                    >
                                        <v-icon dark medium>mdi-pencil</v-icon>
                                    </v-btn>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning">
                        Found no results.
                    </v-alert>
                </template>
            </v-data-table>
        </div>
    </v-app>
</template>

<script>
import API from "@/api/API.js";
import Loading from "@/views/components/Loading.vue";
import GroupMerchantForm from "@/views/components/GroupMerchantForm.vue";
import DetailsForm from "@/views/components/DetailsForm.vue";
import { mapGetters } from "vuex";

export default {
    name: "GroupMerchants",
    components: {
        Loading,
        GroupMerchantForm,
        DetailsForm
    },
    data() {
        return {
            //filter/search variables
            filterQry: {
                qryName: ""
            },
            headers: [],
            merchants: [],
            users: [],
            displayGroupMerchants: [],
            remainderGroupMerchants: [],
            // authorities: [],
            loading: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            groupMerchantFormType: "CREATE",
            selectedGroupMerchant: {},
            page: 1,
            totalGroupMerchants: 0,
            numberOfPages: 0,
            options: {
                rowsPerPage: 10
            },
            details: {
                title: "",
                item: {}
            },
            query: {
                name: "",
                remarks: ""
            },
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false
        };
    },
    watch: {
        options: {
            handler() {
                console.log("1");
                this.queryData();
                this.setData();
            }
        },
        deep: true
    },
    computed: {
        //get role attributes
        ...mapGetters(["attributes", "user", "authorities"])
    },
    async mounted() {
        this.setHeaders();
        //this.queryData()
    },
    methods: {
        hasPermission(authority) {
            if (this.authorities.some(x => x.name === authority)) {
                return true;
            }
            return false;
        },
        async queryData(isSearch = false) {
            this.loading = true;
            if (isSearch) {
                this.options.page = 1;
            }
            const { page, itemsPerPage } = this.options;
            let pageNumber = page - 1;
            try {
                const limit = itemsPerPage;
                const listResponse = await API.getGroupMerchants(pageNumber, limit, this.query);
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`);
                } else {
                    var filteredList = listResponse.groupMerchants;
                    if (page > 1 && this.remainderGroupMerchants.length > 0) {
                        filteredList = this.remainderGroupMerchants.concat(filteredList);
                    }
                    this.totalGroupMerchants = listResponse.totalItems;
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage;
                    this.displayGroupMerchants = filteredList.slice(0, end);
                    this.remainderGroupMerchants = filteredList.length < itemsPerPage ? [] : filteredList.slice(end);
                    this.numberOfPages = listResponse.pageCount;
                    // const hasMoreResponse = await API.getGroupMerchants(this.totalGroupMerchants, 1, this.query)
                    // if (!hasMoreResponse || hasMoreResponse.error) {
                    //     //error getting data
                    //     console.log(`${hasMoreResponse.error}`)
                    // } else {
                    //     var hasMoreList = hasMoreResponse.groupMerchants
                    //     if(hasMoreList.length > 0) {
                    //       this.totalGroupMerchants = this.totalGroupMerchants + 1
                    //       this.numberOfPages = this.numberOfPages + 1
                    //     }
                    // }
                }
                this.loading = false;
            } catch (e) {
                console.log(e);
                this.loading = false;
            }
        },
        async setData() {
            try {
                const listResponse = await API.getMerchants();
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`);
                } else {
                    this.merchants = listResponse.merchants;
                }
            } catch (e) {
                console.log(e);
            }
            try {
                const listResponse = await API.getAllAssignedToUsers();
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`);
                } else {
                    this.users = listResponse.users;
                }
            } catch (e) {
                console.log(e);
            }
        },
        setHeaders() {
            var headers = [
                {
                    text: "Id",
                    value: "id"
                },
                {
                    text: "Name",
                    value: "name"
                },
                {
                    text: "Date Created",
                    value: "dateCreated"
                },
                {
                    text: "Actions",
                    value: "controls",
                    sortable: false
                }
            ];
            this.headers = headers;
        },
        showGroupMerchantForm(what, item = {}) {
            this.groupMerchantFormType = what;
            this.selectedGroupMerchant = item;
            this.showFormDialog = true;
        },
        closeDialog(type) {
            console.log("type:", type);
            if (type === "details") {
                this.details.title = "";
                this.details.item = {};
                this.isShowDetails = false;
            } else if (type === "CREATED" || type === "UPDATE") {
                this.showFormDialog = false;
            } else if (type === "loading") {
                this.loadingDialog = false;
                this.queryData();
            } else if (type === "loading-error") {
                this.loadingDialog = false;
            }
        },
        async saveGroupMerchant(value) {
            this.loadingDialog = true;
            this.isHtml = true;
            this.loadingFinished = false;
            if (value) {
                console.log(value);
                if (value.formType === "CREATED") {
                    this.loadingMessage = `Creating new merchant group <strong><i>${value.name}</i></strong>`;
                    setTimeout(async () => {
                        const createResponse = await API.createGroupMerchant(value);
                        console.log(createResponse);
                        this.loadingFinished = true;
                        if (!createResponse || createResponse.error) {
                            this.isHtml = false;
                            this.loadingMessage = createResponse.error;
                        } else {
                            this.loadingMessage = `Successfully created merchant group <strong><i>${value.name}</i></strong>`;
                            this.showFormDialog = false;
                            this.$refs.GroupMerchantForm.resetForm();
                        }
                    }, 2000);
                } else if (value.formType === "UPDATE") {
                    this.loadingMessage = `Updating merchant group <strong><i>${value.name}</i></strong>`;
                    setTimeout(async () => {
                        const updateResponse = await API.updateGroupMerchant(value);
                        this.loadingFinished = true;
                        if (!updateResponse || updateResponse.error) {
                            this.isHtml = false;
                            this.loadingMessage = updateResponse.error;
                        } else {
                            this.loadingMessage = `Successfully updated merchant group <strong><i>${value.name}</i></strong>`;
                            this.showFormDialog = false;
                            this.$refs.GroupMerchantForm.resetForm();
                        }
                    }, 2000);
                } else {
                    this.loadingMessage = "Invalid command";
                    this.loadingFinished = true;
                }
            } else {
                this.loadingMessage = "Error processing data.";
                this.loadingFinished = true;
            }
        },
        viewGroupMerchant(groupMerchant) {
            this.details.title = groupMerchant.name;
            this.details.item = groupMerchant;
            this.isShowDetails = true;
        },
        search(event, type) {
            this.query[type] = event;
        }
    }
};
</script>

<style scoped>
.v-data-table {
    max-width: 100%;
}
</style>
