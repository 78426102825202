<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update Merchant Group</span>
                <span class="headline" v-else>Create New Merchant Group</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="Id*" v-if="formType === 'UPDATE'" v-model="form.id" :readonly="formType === 'UPDATE'" required :rules="[rules.requiredField]" data-cy="form-id"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Name*" v-model="form.name" required :rules="[rules.requiredField]" data-cy="form-name"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Remarks" v-model="form.remarks" data-cy="form-remarks"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-autocomplete clearable chips deletable-chips multiple :items="merchantList" item-text="name" item-value="merchantId" label="Merchant" v-model="form.merchants" return-object :data-cy="'form-merchants-' + merchantList">
                                </v-autocomplete>
                            </v-flex>
                            <v-flex xs12>
                                <v-autocomplete clearable chips deletable-chips multiple :items="userList" item-text="username" item-value="username" label="User" v-model="form.users" return-object :data-cy="'form-users-' + userList">
                                </v-autocomplete>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
        merchants: Array,
        users: Array
    },
    computed: {
        populatedForm() {
            return {
                id: this.form.id,
                name: this.form.name,
                remarks: this.form.remarks,
                merchants: this.form.merchants ? JSON.stringify(this.form.merchants.map(({id, merchantId}) => ({id, merchantId}))) : null,
                users: this.form.users ? JSON.stringify(this.form.users.map(({id, username}) => ({id, username}))) : null,
                formType: this.formType
            }
        },
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        merchants: {
            handler(value) {
                this.merchantList = value
            }
        },
        users: {
            handler(value) {
                this.userList = value
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            merchantList: this.merchants ?? [],
            userList: this.users ?? [],
            form: {
              merchants: [],
              users: [],
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-group-merchant', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    name: undefined,
                    remarks: undefined,
                    merchant: [],
                    user: [],
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
                this.form = {
                    id: this.selectedItem.id,
                    name: this.selectedItem.name,
                    remarks: this.selectedItem.remarks,
                    merchants: this.selectedItem.merchants ?? [],
                    users: this.selectedItem.users ?? []
                }
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        onlyForCurrency ($event) {
            // console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

            // only allow number and one dot
            if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.form.amount.indexOf('.') != -1)) { // 46 is dot
                $event.preventDefault();
            }

            // restrict to 2 decimal places
            if(this.form.amount!=null && this.form.amount.indexOf(".")>-1 && (this.form.amount.split('.')[1].length > 1)){
                $event.preventDefault();
            }
        },
        getStatus: async function (val) {
            console.log('val', val)
                this.form.status = val
        },
    }
}
</script>
